import { useState, useEffect } from "react"

export const useHashUrl = () => {
  const [hash, setHash] = useState<string>(() =>
    typeof window !== "undefined" ? window.location.hash.slice(1) : "",
  )

  useEffect(() => {
    const listener = () => setHash(window.location.hash.slice(1))

    window.addEventListener("hashchange", listener)

    return () => {
      window.removeEventListener("hashchange", listener)
    }
  }, [])

  return hash
}
