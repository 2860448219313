import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import Jumbotron from "src/components/Jumbotron"
import { Props, Resource } from "./types"
import * as styles from "./styles.module.scss"
import { usePages } from "src/context/pages"
import ResourceCard from "src/components/ResourceCard"
import ResourceCardPosts from "src/components/ResourceCardPosts"
import RowLink from "src/components/RowLink"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath, getPage } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <Jumbotron title={texts.header} subtitle={texts.subheader} />
      <div className={`content-wrapper ${styles.resources}`}>
        <h2 className={styles.sectionTitle}>{texts.videos.title}</h2>
        {texts.videos.listVideos.map((video: any) => (
          <ResourceCard
            key={video.videoId}
            {...video}
            linkText={texts.videos.linkText}
          />
        ))}
        <h2 className={styles.sectionTitle}>{texts.posts.title}</h2>
        {texts.posts.listPosts.map((post: any) => (
          <ResourceCardPosts
            key={post.title}
            {...post}
            linkText={texts.posts.linkText}
          />
        ))}
        <h2
          className={`${styles.sectionTitle} ${styles.sectionTitleResources}`}
        >
          {texts.additionalResourcesTitle}
        </h2>
        {texts.additionalResources.map((resource: Resource) => (
          <RowLink
            key={resource.url}
            title={resource.title}
            url={getPage(resource.url).url}
            target={resource.target}
          />
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        videos {
          title
          linkText
          listVideos {
            title
            description
            videoId
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        posts {
          title
          linkText
          listPosts {
            title
            description
            url
            img
          }
        }
        additionalResourcesTitle
        additionalResources {
          title
          url
          target
        }
      }
    }
  }
`

export default Template
