import React, {
  FC,
  useRef,
  createContext,
  useContext,
  useCallback,
} from "react"
import CloseIcon from "src/components/Icons/Close"
import { useLockBodyScroll } from "src/hooks/useLockBodyScroll"
import { useHashUrl } from "src/hooks/useHashUrl"
import { useClickAwayEffect } from "src/hooks/useClickAwayEffect"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import HideDraft from "src/components/HideDraft"

type ModalContextProps = { isOpen: boolean }
const ModalContext = createContext<ModalContextProps>({ isOpen: false })
export const useModal = () => useContext(ModalContext)
function ModalProvider({
  isOpen,
  children,
}: ModalContextProps & { children: React.ReactNode }) {
  return (
    <ModalContext.Provider value={{ isOpen }}>{children}</ModalContext.Provider>
  )
}

const Modal: FC<Props> = ({ children, id }) => {
  const hashUrl = useHashUrl()
  const isOpen = hashUrl === id

  if (!isOpen) {
    return null
  }

  return (
    <ModalProvider isOpen={isOpen}>
      <ModalContent id={id}>{children}</ModalContent>
    </ModalProvider>
  )
}

export default Modal

function ModalContent({
  children,
  id,
}: {
  children: React.ReactNode
  id: string
}) {
  const ref = useRef<HTMLDivElement>(null)

  useClickAwayEffect(
    ref,
    useCallback(() => {
      window.location.hash = ""
    }, []),
    true,
  )

  const contentRef = useRef<HTMLDivElement>(null)
  useLockBodyScroll(contentRef, true)

  return (
    <>
      <HideDraft />

      <div className={`${styles.modalOverlay} ${styles.open}`} id={id}>
        <a className={styles.close} href="#">
          <CloseIcon />
        </a>
        <div className={styles.modalContent} ref={contentRef}>
          <div ref={ref}>{children}</div>
        </div>
      </div>
    </>
  )
}
