import React from "react"
import { useModal } from "../Modal"

export default function Youtube({ videoId }: { videoId: string }) {
  const { isOpen } = useModal()

  if (!isOpen) {
    return null
  }

  return (
    <iframe
      src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0;autoplay=1;loop=0;showinfo=0;controls=1`}
      // @ts-ignore
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  )
}
