import React, { useEffect } from "react"

export function useClickAwayEffect(
  ref: React.RefObject<HTMLElement>,
  handler: () => void,
  active: boolean = true,
) {
  useEffect(() => {
    if (!active) return

    const listener = (event: Event) => {
      event.preventDefault()

      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return
      }

      handler()
    }

    document.addEventListener("click", listener)
    document.addEventListener("touchend", listener)

    return () => {
      document.removeEventListener("click", listener)
      document.removeEventListener("touchend", listener)
    }
  }, [ref, handler, active])
}
