import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import ArrowLink from "src/components/ArrowLink"
import Link from "src/utils/Link"

const ResourceCardPosts: FC<Props> = ({
  description,
  title,
  img,
  linkText,
  url,
}) => {
  return (
    <Link className={styles.card} to={url}>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <ArrowLink
          as="span"
          text={linkText}
          to={url}
          className={styles.arrowLink}
        />
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={img} />
      </div>
    </Link>
  )
}

export default ResourceCardPosts
