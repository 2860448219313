import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Close"
      className="cls-1"
      d="M33.308,37.554L18.984,23.231,4.661,37.554a1.5,1.5,0,0,1-2.123,0L0.415,35.431a1.5,1.5,0,0,1,0-2.123L14.738,18.984,0.415,4.661a1.5,1.5,0,0,1,0-2.123L2.538,0.415a1.5,1.5,0,0,1,2.123,0L18.984,14.738,33.308,0.415a1.5,1.5,0,0,1,2.123,0l2.123,2.123a1.5,1.5,0,0,1,0,2.123L23.231,18.984,37.554,33.308a1.5,1.5,0,0,1,0,2.123l-2.123,2.123A1.5,1.5,0,0,1,33.308,37.554Z"
    />
  </svg>
))
