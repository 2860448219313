import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import Image from "gatsby-image"
import ArrowLink from "src/components/ArrowLink"
import Youtube from "src/components/Youtube"
import Modal from "src/components/Modal"
import Play from "src/images/video-play-button.png"

const ResourceCard: FC<Props> = ({
  description,
  title,
  img,
  linkText,
  videoId,
}) => {
  return (
    <>
      <a className={styles.card} href={`#${videoId}`}>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description}</p>
          <ArrowLink
            as="span"
            text={linkText}
            to={""}
            className={styles.arrowLink}
          />
        </div>
        <div className={styles.imageContainer}>
          <img src={Play} className={styles.play} />
          <Image
            className={styles.image}
            fluid={img.childImageSharp.fluid}
            aria-hidden={true}
          />
        </div>
      </a>
      <Modal id={videoId}>
        <div className={styles.modal}>
          <Youtube videoId={videoId} />
        </div>
      </Modal>
    </>
  )
}

export default ResourceCard
